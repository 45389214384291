<template>
    <div class="app-container">
        <div>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="单位:">
                    <el-select v-model="search.institutionId" size="medium" filterable  clearable @change="handleInstitution" placeholder="请选择单位" class="form-line-item"
                               ref="selectInstitution"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                                   :value="item.institutionId"></el-option>
                    </el-select>
<!--                    <el-input placeholder="请输入单位名称" v-model="search.institutionName" size='medium' class="form-line-item"></el-input>-->
                </el-form-item>
                <el-form-item label="批次:">
                    <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                               class="form-line-item" :disabled='batchShow'
                               ref="selectBatchId"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                                   :value="item.batchId"></el-option>
                    </el-select>
<!--                    <el-input placeholder="请输入批次名称" v-model="search.batchName" size='medium' class="form-line-item"></el-input>-->
                </el-form-item>
                <el-form-item label="负责人:">
                    <el-select v-model="search.responsiblePerson" size='medium' clearable placeholder="请选择负责人"
                               class="form-line-item" >
                        <el-option v-for="(item,index) in responsiblePersonList" :key='item.id' :label="item.label"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态:">
                  <el-select v-model="search.batchState" size='medium' clearable placeholder="请选择批次状态"
                             class="form-line-item" >
                    <el-option v-for="(item,index) in batchStateList" :key='item.key' :label="item.value"
                               :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="交付时间:">
                  <el-date-picker v-model="search.dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                  format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="medium"></el-date-picker>
                </el-form-item>
                <el-form-item style="margin-left:10px;">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="getBatchDataList">
                            搜索
                    </el-button>
                    <el-button type="primary" size="small" icon="el-icon-refresh" @click="resetHandle">
                            重置
                    </el-button>
                </el-form-item>
            </el-form>

        </div>

        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="small" icon="el-icon-user-solid" @click="getBatchDistribution">批次分配
                    </el-button>
                    <el-popconfirm title="是否确认核验？" @confirm="checkBatch">
                      <el-button type="success" size="small" icon="el-icon-success" slot="reference" style="margin-left:10px;">批次核验</el-button>
                    </el-popconfirm>
                    <el-popconfirm title="是否确认删除？" @confirm="deleteBatch">
                      <el-button type="danger" size="small" icon="el-icon-delete" slot="reference" style="margin-left:10px;"
                                 v-show="$store.state.user.info.buttons.indexOf('btn.batchDistribution.deleteBatch') != -1">批次删除</el-button>
                    </el-popconfirm>
<!--                    <el-button type="success" size="small" icon="el-icon-success" @click="checkBatch">批次核验-->
<!--                    </el-button>-->
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                       highlight-hover-row align="center" ref="myTable" :data="batchDataList"
                       class="mytable-scrollbar financialList" show-footer
                       :checkbox-config="{trigger: 'row', highlight: true, range: true}" :export-config="{}" :print-config="{}"
                       :loading='loading'>
<!--                <vxe-column type='seq' title="序号" width="6%" :show-overflow="'tooltip'"></vxe-column>-->
                <vxe-column type='checkbox' title="全选" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="institutionName" title="单位名称" width="300" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="batchName" title="批次名称" width="300" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="paperNum" title="论文数" width="100" :show-overflow="'tooltip'"></vxe-column>
                <!--  -->
                <vxe-column field="viewNum" title="意见数" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="unSubmissionNum" title="未提交" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="submissionTime" title="专家提交时间" width="150" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="returnTime" title="院校返回时间" width="150" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="responsiblePerson" title="负责人" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="deliveryTime" title="负责人交付时间" width="150" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="batchState" title="状态" width="100" :show-overflow="'tooltip'">
                  <template #default="{row}">
                    {{row.batchState == 0?"待分配":row.batchState == 1?"待确认":row.batchState == 2?"送审中":row.batchState == 3?"已交付":row.batchState == 4?"已归档":"已核验"}}
                  </template>
                </vxe-column>
                <!--  -->
<!--                <vxe-column field="createTime" title="创建时间" width="100" :show-overflow="'tooltip'" sortable></vxe-column>-->
<!--                <vxe-column field="fileDatetime" title="归档时间" width="100" :show-overflow="'tooltip'" sortable>-->
<!--                    <template #default="{ row }">-->
<!--                        {{row.fileDatetime!=null?row.fileDatetime:'未归档'}}-->
<!--                    </template>-->
<!--                </vxe-column>-->
<!--                <vxe-column field="isFiled" title="归档状态" width="14%" :show-overflow="'tooltip'" sortable>-->
<!--                    <template #default="{ row }">-->
<!--                        {{row.isFiled==1?'已归档':'未归档'}}-->
<!--                    </template>-->
<!--                </vxe-column>-->
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                       :total="page.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
                       :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                       @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
        <!-- 上传打款表 -->

                <!-- 添加用户 -->
        <vxe-modal v-model="isBatchDistributionShow" :position="{top: 5}" width="700" show-zoom resize :transfer='true'>
            <template #title>
                批次分配
                <div>
                    <span>请选择分配用户</span>
                    <el-select v-model="value" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.id"></el-option>
                    </el-select>
                    <el-popconfirm title="是否确认分配？" @confirm = "batchDistribution">
                        <el-button type="primary"  slot="reference">确认分配</el-button>
                    </el-popconfirm>
                </div>
            </template>
            <div>
            </div>
        </vxe-modal>

    </div>
</template>

<script>
import XLSX from 'xlsx'
import batchManagement from "@/api/batchManagement";
export default {
    name: 'unpay',
    data() {
        return {
            uploadModel: false,
            loading: false,


            batchStateList:[
                {key:0,value:"待分配"},
                {key:1,value:"待确认"},
                {key:2,value:"送审中"},
                {key:3,value:"已交付"},
                {key:4,value:"已归档"},
                {key:5,value:"已核验"},
            ],
            responsiblePersonList:[],
            batchShow: true,
            isBatchDistributionShow: false,
            value: '',
            distributionBatchList: [],
            checkBatchList: [],
            deleteBatchList: [],
            institutionList: [],
            batchList: [],
            batchDataList: [],
            search: {
                institutionId:'',
                batchId:"",
                institutionName: '',
                batchName: '',
                responsiblePerson: '',
                dateRange: ''
            },
            // 分页
            page: {
                currentPage: 1,
                pageSize: 10,
                pageCount: 40, //自己用
                pageSizes: [10, 20, 50, 100, 200],
                total: 0
            },
            options: [{
                id: '选项1',
                label: '黄金糕'
            },{
                id: '选项2',
                label: '双皮奶'
            }]

        }
    },
    created() {
        window.addEventListener('keydown', this.handleKeyPress);
        this.getInstitution();
        this.getBatchDataList();
    },
    methods: {
        handleKeyPress(event) {
            if (event.keyCode === 13) {
                this.getBatchDataList();
            }
        },

        //表格刷新
        refresh() {
            this.search ={
              institutionId:'',
              batchId:"",
              institutionName: '',
              batchName: '',
              responsiblePerson: '',
              dateRange: ''
            }
            this.getBatchDataList();
        },
        //获取送审单位
        getInstitution() {
            // let params = new URLSearchParams();
            // params.append("isFiled", null);
            let params = {

                isFiled: null
            }
            console.log(params);
            this.$api.schoolCustom.getbatchDistributionInstitution(params)
                .then(res => {
                    console.log(res);
                    this.institutionList = res.data.data;
                })
                .catch(err => {
                    this.$message.warning('接口错误');
                })
            this.$api.batchManagement.getDistributionUserList()
                .then(res => {
                  if (res.data.code == 200){
                    this.responsiblePersonList = res.data.data;
                    console.log(this.responsiblePersonList);
                  }
                }).catch(err => {
                  this.$message.warning('服务器维护');
            })
        },
        //获取送审单位的批次
        getBatch(institutionId) {
            // let params = new URLSearchParams();
            // params.append("institutionId", institutionId);
            // params.append("isFiled",null);

            let params = {
                institutionId:institutionId,
                isFiled: null
            }
            console.log(params)
            this.$api.sendingP2p.getBatch(params)
                .then(res => {
                    console.log(res.data.data);
                    this.batchList = res.data.data;
                })
                .catch(err => {
                    this.$message.warning("服务器维护");
                });
        },
        //机构选择改变
        handleInstitution(val) {
            this.search.batchId = '';
            if (val == '') {
                this.batchShow = true;
                return;
            }
            this.batchShow = false;
            this.getBatch(val);
        },
        getBatchDataList() {
            console.log(1)
            this.loading = true;
            // let param = new URLSearchParams();
            // param.append("institutionName", this.search.institutionName);
            // param.append("batchName", this.search.batchName);
            // param.append("isFiled", this.search.isFiled);
            //
            // param.append("pageNum", this.page.currentPage);
            // param.append("pageSize", this.page.pageSize);
            let param ={
                // institutionName:this.search.institutionName,
                // batchName:this.search.batchName,
                institutionId:this.search.institutionId,
                batchId:this.search.batchId,
                sysUserId:this.search.responsiblePerson,
                batchState:this.search.batchState,
                startTime:this.search.dateRange[0],
                endTime:this.search.dateRange[1],
                pageNum:this.page.currentPage,
                pageSize:this.page.pageSize,
            }
            console.log(param);
            this.$api.batchManagement.getWorkBatchList(param)
                .then(res => {
                    console.log(res.data.data);
                    this.batchDataList = res.data.data;
                    this.page.total = res.data.count;
                    this.loading = false;
                }).catch(err => {
                this.$message.warning("服务器维护！");
            });
        },
        // 获取分配批次及分配用户
        getBatchDistribution(){
            // 得到所有checkbox
            let ids = [];
            // console.log(this.$refs.myTable.getCheckboxRecords())
            let checkBox = this.$refs.myTable.getCheckboxRecords();
            for(let i=0;i<checkBox.length;i++){
                ids.push(checkBox[i].batchId);
            }
            this.distributionBatchList = ids;
            if(this.distributionBatchList.length == 0){
                this.$message.warning('请至少选择一个批次');
            }else{
                this.$api.batchManagement.getDistributionUserList()
                    .then(res => {
                        if(res.data.code == 200){
                            this.options = res.data.data;
                            console.log(this.options);
                            this.isBatchDistributionShow = true;
                            this.value = '';
                        }else{
                            this.$message.warning('服务器维护')
                        }
                    })
            }
        },
        // 批次分配
        batchDistribution(){
            let params = new URLSearchParams();
            params.append('sysUserId', this.value);
            this.$api.batchManagement.getUserCurBatchList(params)
                .then(res => {
                    if(res.data.code == 200){
                        let curBatchList = res.data.data;
                        let distributionBatchList = this.distributionBatchList.filter(item => {return curBatchList.indexOf(item) < 0});
                        if(distributionBatchList.length != 0){
                            let params2 = {
                                "sysUserId": this.value,
                                "distributionBatchList": distributionBatchList
                            }
                            this.$api.batchManagement.batchDistribution(params2)
                                .then(res => {
                                    if(res.data.code == 200){
                                        this.$message.success('批次分配成功');
                                        this.refresh();
                                        this.isBatchDistributionShow = false;
                                    }else{
                                        this.$message.warning('服务器维护')
                                    }
                                })
                        }else{
                            this.$message.warning('当前用户已被分配所选批次，请勿重复分配')
                        }
                    }else{
                        this.$message.warning('服务器维护')
                    }
                })
            // let params2 = {
            //     "sysUserId": this.value,
            //     "distributionBatchList": this.distributionBatchList
            // }
            // console.log(params);
        },
        // 批次核验
        checkBatch(){
          // 得到所有checkbox
          let ids = [];
          // console.log(this.$refs.myTable.getCheckboxRecords())
          let checkBox = this.$refs.myTable.getCheckboxRecords();
          for(let i=0;i<checkBox.length;i++){
            ids.push(checkBox[i].batchId);
          }
          this.checkBatchList = ids;
          if(this.checkBatchList.length == 0){
            this.$message.warning('请至少选择一个批次');
          }else{
            let params = {
              "distributionBatchList": this.checkBatchList
            }
            this.$api.batchManagement.checkBatch(params)
                .then(res => {
                  if (res.data.code == 200){
                    this.$message.success("批次核验成功")
                    this.refresh();
                  }else{
                    this.$message.warning('服务器维护')
                  }
                })
          }
        },
        deleteBatch(){
          // 得到所有checkbox
          let ids = [];
          // console.log(this.$refs.myTable.getCheckboxRecords())
          let checkBox = this.$refs.myTable.getCheckboxRecords();
          for(let i=0;i<checkBox.length;i++){
            ids.push(checkBox[i].batchId);
          }
          this.deleteBatchList = ids;
          if(this.deleteBatchList.length == 0){
            this.$message.warning('请至少选择一个批次');
          }else{
            let params = {
              "distributionBatchList": this.deleteBatchList
            }
            this.$api.batchManagement.deleteBatch(params)
                .then(res => {
                  if (res.data.code == 200){
                    this.$message.success("批次删除成功")
                    this.refresh();
                  }else{
                    this.$message.warning('服务器维护')
                  }
                })
          }
        },
        resetHandle() {
            this.search = {
                institutionId:'',
                batchId: "",
                institutionName: '',
                batchName: '',
                isFiled:'',
            }
        },
        getFileName(str) {
            return str.substr(str.indexOf('\\') + 1)
        },

        //分页操作
        handlePageChange({
                             currentPage,
                             pageSize
                         }) {
            console.log(currentPage, pageSize);
            this.page.currentPage = currentPage;
            this.page.pageSize = pageSize;
            this.getBatchDataList();
        },
        //每页多少数据改变
        handleChangePageSize(val) {
            this.page.pageSize = val;
            this.page.currentPage = 1;
            this.getBatchDataList();
        },
        firstPage() {
            this.page.currentPage = 1;
            this.getBatchDataList();
        },

        //头部样式
        headerCellStyle({
                            row,
                            column,
                            rowIndex,
                            columnIndex
                        }) {
            return "background-color:#e0e0e0f1"
        },
        //设置单元格样式
        cellStyle({
                      row,
                      rowIndex,
                      column,
                      columnIndex
                  }) {
            //设置状态颜色
            let styles = {};
            if (column.property == 'payStatue') {
                styles['color'] = this.payStatue[row.payStatue].color;
            }
            return styles;
        },
        dateFormat: function (time) {
            var datetime = new Date(time);
            // datetime.setTime(time);
            var year = datetime.getFullYear();
            var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime
                    .getMonth() +
                1;
            var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
            return year + "-" + month + "-" + date;
        },
        isShowSelectOptions(isShowSelectOptions){
            if(!isShowSelectOptions) this.$refs.selectInstitution.blur();
            if(!isShowSelectOptions) this.$refs.selectBatchId.blur();

        },
    },
    components: {

    }
}
</script>

<style scoped>
.form-line-item {
    width: 15em;
}

.green {
    color: green;
}

.red {
    color: red;
}

.el-dialog__body {
    padding-bottom: 0px;
}

.yzmBtn {
    margin-left: 5px;
}
.el-form-item {
    margin-bottom: 10px !important;
}
</style>

<style>
.financialList .vxe-table--body-wrapper {
    height: calc(100vh - 390px);
}
</style>